<template>
<div>
    <v-container>
        <v-row>
            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Reporte General" class="tran"></s-toolbar>
                <v-card>

                    <v-row justify="center" style="margin-top: 4px; margin-left: 10px; margin: auto">
                        <v-col cols="4" lg="4" md="4">
                            <s-select-definition :def="1131" v-model="TypeMonth" label="Seleccione mes"></s-select-definition>
                        </v-col>
                        <v-col cols="4" lg="2" md="3">
                            <s-text label="Ingrese año" number v-model="txtYear"></s-text>
                        </v-col>

                        <v-col cols="4" lg="4" md="4" class="mt-4 pl-0">
                            <v-btn  outlined elevation="0" :color="'warning'" small @click="runView()"><i style="font-size: 20px" class="fas fa-eye mr-1"></i> Visualizar</v-btn>
                            <v-btn class="ml-2"  outlined elevation="0"  :color="'success'" small @click="downloadReport()"><i style="font-size: 20px" class="fas fa-file-pdf mr-1"></i> Exportar</v-btn>
                        </v-col>
                        <!-- <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                            <v-btn  outlined elevation="0"  :color="'success'" small @click="downloadReport()"><i style="font-size: 20px" class="fas fa-file-pdf"></i> Exportar</v-btn>
                        </v-col> -->

                    </v-row>

                    <v-tabs v-model="currentItem">
                        <v-tab href="#Dashboard"> Dashboard </v-tab>
                        <v-tab href="#DashboardForProject"> Dashboard por Proyecto</v-tab>

                        <v-tab href="#Report"> Datos</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="currentItem">
                        <v-tab-item :value="'Dashboard'">
                            <cob-dasboard-drill  :items="dataDashboard"></cob-dasboard-drill>
                        </v-tab-item>
                    
                    </v-tabs-items>

                     <v-tabs-items v-model="currentItem">
                        <v-tab-item :value="'DashboardForProject'">
                            <cob-dasboard-drill-project   :itemProject="itemProject"></cob-dasboard-drill-project>
                            <!-- :items="dataDashboard" -->
                        </v-tab-item>
                    </v-tabs-items>

                    <v-tabs-items v-model="currentItem">
                        <v-tab-item :value="'Report'">
                            <v-row style="margin:auto">
                                <v-col cols="12">
                                    <v-data-table :height="items.length > 0 ? '400px': 'auto'" :headers="headers" :items="items" dense :footer-props="{
                                    showCurrentPage: true,
                                    showFirstLastPage: true,
                                    itemsPerPageOptions: [5, 10, 50, 100],
                                }">
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                    </v-tabs-items>

                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="processing" v-if="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Esto puede tardar unos minutos, Por favor espere.
                    <v-progress-linear indeterminate color="white" class="mb-0 mt-3"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al realizar busqueda <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</div>
</template>

<script>
import _sAccounting from '@/services/Accounting/ConChargeMasive.js'
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";

import CobDasboardDrill from './CobDasboardDrill.vue';
import CobDasboardDrillProject from './CobDasboardDrillProject.vue';

export default {
    props: {

    },
    components: {
        CobDasboardDrill,
        CobDasboardDrillProject
    },
    data() {
        return {
            currentItem: "tab-Funciones",
            processing: false,
            messageProcessing: "",

            items: [],
            headers: [{
                text: "",
                value: ""
            }],

            TypeMonth: null,
            txtYear: this.$fun.getYear(),
            report: {},
            group: [],
            groupProject: [],
            groupAll: [],

            dataDashboard: [],
            objDrilldown: [],
            itemProject: []
        };
    },

    methods: {

        comparar(a, b) {
            return a - b;
        },

        runView() {

            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Month, Year"
            this.report.QryParamsArrayValue = this.TypeMonth + "," + this.txtYear //this.DateBegin + "," + this.DateEnd + "," + this.number;

            this.report.QryNameProcedure = "CobSearchCustomerGestionAll_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(this.report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;

                            if (resp.data.length > 0) {
                                resp.data.forEach(element => {
                                    element.FecGestiòn = element.FecGestiòn == null ? "" : this.$moment(element.FecGestiòn).format(this.$const.FormatDateDB);
                                });
                            }

                            this.items = resp.data;

                            

                            //*********************** DATA PARA DASHBOARD POR PROYECTO ********************/
                            
                            var objTmp = {},
                                objProject = {}

                            this.dataDashboard = []
                            this.itemProject = []
                            this.group = []

                            this.groupProject = _.groupBy(this.items, "Proyecto");
                            console.log(this.groupProject)

                            for (let propProject in this.groupProject) {
                                objProject = {}
                                objProject.Project = "Proyecto"
                                objProject.Name = propProject

                                this.itemProject.push(objProject)
                                

                            }
                            this.itemProject.sort()

                            this.itemProject.forEach(element => {
                                element.itemsDay = []

                                this.group = _.groupBy(this.items.filter(x => x.Proyecto == element.Name), "Dia");

                                var countForMonth = 0
                                for (let prop in this.group) {

                                    objTmp = {}
                                    countForMonth = 0
                                    this.group[prop].map((i) => {
                                        countForMonth = countForMonth + 1
                                    });

                                    //GRAFICO BAR
                                    objTmp.name = prop
                                    objTmp.y = countForMonth

                                    element.itemsDay.push(objTmp)
                                    element.itemsDay.sort((a, b) => {
                                        return a.name - b.name;
                                    });
                                }
                            });

                            

                            /*  this.dataDashboard.sort((a, b) => {
                                 return a.name - b.name;
                             }); */

                            //console.log("proyectos",this.itemProject)

                            //console.log("this.itemProject", this.itemProject)

                            //************************** FIN  *************************************/
                            

                            this.groupAll = _.groupBy(this.items, "Dia");
                            var countForMonth = 0
                                for (let prop in this.groupAll) {

                                    objTmp = {}
                                    countForMonth = 0
                                    this.groupAll[prop].map((i) => {
                                        countForMonth = countForMonth + 1
                                    });

                                    //GRAFICO BAR
                                    objTmp.name = prop
                                    objTmp.y = countForMonth

                                    this.dataDashboard.push(objTmp)
                                    this.dataDashboard.sort((a, b) => {
                                        return a.name - b.name;
                                    });
                                    
                                }



                            console.log(this.items)

                            for (
                                var i = 0; i < Object.keys(this.items[0]).length; i++
                            ) {
                                this.headers.splice(i, i, {
                                    text: Object.keys(this.items[0])[i],
                                    value: Object.keys(this.items[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadReport() {
            var name = "";
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Month, Year"
            this.report.QryParamsArrayValue = this.TypeMonth + "," + this.txtYear //this.DateBegin + "," + this.DateEnd + "," + this.number;

            this.report.QryNameProcedure = "CobSearchCustomerGestionAll_R";
            name = "REPORTE GESTION DE CLIENTES"

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(this.report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                name
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        }

    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
