<template>
<div>
    <v-container class="px-0">
        <v-col cols="12">
            <v-row>
                <v-col cols="12" lg="6" md="6" v-for="(item, index) in itemProject" :key="index">
                    <high-charts-drill-bar
                        :id="index" 
                        :items="item.itemsDay"
                        :visibleTitle="true"
                        :title="'PROYECTO ' + item.Name"
                        :number="true"
                        :name="'Dia'"
                    />
                    <br>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</div>
</template>

<script>
import HighChartsDrillBar from '../../components/Utils/HighCharts/HighChartsDrillBar.vue';

export default {
    props: {
       /*  items: {
            type: Array,
            default: []
        }, */
        itemProject: {
            type: Array,
            default: []
        },
        
    },
    components: {
        HighChartsDrillBar
    },
    data() {
        return {

        };
    },
    methods: {},
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
